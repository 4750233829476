/*
 * @Author: 吴永生 15770852798@163.com
 * @Date: 2023-11-13 16:40:56
 * @LastEditors: 吴永生 15770852798@163.com
 * @LastEditTime: 2023-12-08 14:52:25
 * @FilePath: /kuaichengji-web/src/store/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createSlice } from '@reduxjs/toolkit';
import { creatSetState, mapBaseReducers } from '@/utils/reduxUtils';
import { IUserVipInfo, User } from '@/services/user';
import { School, SchoolInfo, SchoolYear } from '@/services/school';

export type IPersonalCenterModalDataKeys = 'coupon' | 'history' | 'shareLink';
export interface UserState {
  isLogin: boolean;
  hasInit: boolean;
  userInfo?: User;
  school?: School;
  schoolWithName?: SchoolInfo;
  schoolYear?: SchoolYear;
  userVipInfo?: IUserVipInfo;
  accessPerms?: string[];
  showMenuGuide: boolean;
  personalCenterModalData?: {
    open: boolean;
    tabKey: IPersonalCenterModalDataKeys;
  };
  openGetVipModal: boolean; // 显示获取vip弹窗
}

const initialState: UserState = {
  isLogin: false,
  hasInit: false,
  userInfo: undefined,
  school: undefined,
  schoolWithName: undefined,
  schoolYear: undefined,
  userVipInfo: undefined,
  accessPerms: undefined, // 用户权限标识符列表
  showMenuGuide: false, // 是否显示菜单引导
  personalCenterModalData: undefined, // 是否显示个人中心弹窗及tabs页面key
  openGetVipModal: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setUserState = creatSetState('user');

export default userSlice;
