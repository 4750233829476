import {
  APP_URL,
  KUAICJ_USER_INFO,
  KUAICJ_USER_TOKEN,
} from '@/config/constants';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs';
import { setFeedbackState } from '@/store/feedback';
import { getBackUrl, isLoginPage } from '@/utils/toolUtls';

export const httpStatusMessageMap = new Map<number, string>([
  [400, '请求错误(400)'],
  [401, '未授权，请重新登录(401)'],
  [403, '拒绝访问(403)'],
  [404, '请求出错(404)'],
  [408, '请求超时(408)'],
  [500, '服务器错误(500)'],
  [501, '服务未实现(501)'],
  [502, '网络错误(502)'],
  [503, '服务不可用(503)'],
  [504, '网络超时(504)'],
  [505, 'HTTP版本不受支持(505)'],
]);

export const getErrorMessage = (status: number): string => {
  return httpStatusMessageMap.get(status) || '服务不可用，请稍后重试！';
};

export const setAuthHeaders = (headers: Headers) => {
  const token = window.localStorage.getItem(KUAICJ_USER_TOKEN);

  const searchParams = new URLSearchParams(window.location.href?.split('?')[1]);
  const shareToken = searchParams.get('shareToken') || '';
  if (shareToken) {
    headers.set('shareToken', shareToken || '');
  }
  headers.set('memberToken', token || '');
};

export const getAuthHeaders = () => {
  const token = window.localStorage.getItem(KUAICJ_USER_TOKEN);

  return {
    memberToken: token || '',
  };
};

const isUnAuthCode = (code: number) => {
  return code === 1;
};

const isErrorCode = (code: number) => {
  return code !== 200 && code !== 1 && code !== 4;
};

const isServerError = (response: Response) => {
  return !(response.status >= 200 && response.status < 300);
};

const isTokenInvalid = (code: number, message: string) => {
  return code === 303 && message === 'token过期';
};

export interface CreateValidateStatusConfig {
  onUnAuth?: (defaultAction: () => void) => void;
  onError?: (response: Response, body: any, defaultAction: () => void) => void;
  onTokenInvalid?: (defaultAction: () => void) => void;
}

let globalModalErrorLock = false;

export const createValidateStatus =
  (config?: CreateValidateStatusConfig) => (response: Response, body: any) => {
    // 服务异常
    if (isServerError(response)) {
      setFeedbackState({
        globalMessageError: {
          level: 'error',
          message: getErrorMessage(response.status),
        },
      });
      return false;
    }

    // errorShowType: 0无提示, 1message错误提示，2modal错误提示, 3modal警告提示, default: 1
    // errorCompProps: 错误组件props
    const { code, msg, errorShowType = 1 } = body || {};

    // token失效后提示，确定后返回登录页
    const defaultTokenInvalidAction = () => {
      if (!globalModalErrorLock) {
        globalModalErrorLock = true;
        setFeedbackState({
          globalModalError: {
            level: 'warning',
            message: '由于您长时间未登录，缓存的数据已被清除，请及时登录。',
            modalConfig: {
              okText: '确定',
            },
            onOk: () => {
              localStorage.removeItem(KUAICJ_USER_TOKEN);
              localStorage.removeItem(KUAICJ_USER_INFO);
              window.location.reload();
            },
          },
        });
      }
    };

    // token验证失败后返回登录页
    const defaultUnAuthAction = () => {
      localStorage.removeItem(KUAICJ_USER_TOKEN);
      localStorage.removeItem(KUAICJ_USER_INFO);
      if (!isLoginPage()) {
        window.location.href = `${APP_URL}#/login?backUrl=${getBackUrl()}`
      }
    };

    // 业务接口异常提示
    const defaultOnErrorAction = () => {
      const showType = errorShowType ?? 1;
      const errorMsg = msg || getErrorMessage(response.status);

      if (showType === 1) {
        setFeedbackState({
          globalMessageError: {
            level: 'error',
            message: errorMsg,
          },
        });
      } else if (showType === 2) {
        setFeedbackState({
          globalModalError: {
            level: 'error',
            message: errorMsg,
          },
        });
      } else if (showType === 3) {
        setFeedbackState({
          globalModalError: {
            level: 'warning',
            message: errorMsg,
          },
        });
      }
    };

    if (isTokenInvalid(code, msg)) {
      if (config?.onTokenInvalid) {
        config.onTokenInvalid(defaultTokenInvalidAction);
      } else {
        defaultTokenInvalidAction();
      }
      return false;
    }

    if (isUnAuthCode(code)) {
      if (config?.onUnAuth) {
        config.onUnAuth(defaultUnAuthAction);
      } else {
        defaultUnAuthAction();
      }
      return false;
    }

    if (isErrorCode(code)) {
      if (config?.onError) {
        config.onError(response, body, defaultOnErrorAction);
      } else {
        defaultOnErrorAction();
      }
      return false;
    }

    return true;
  };

// 使用modal.warning进行提示
export const showWarningModalResponseHandler = async (res: Response) => {
  const body = await res.json();
  body.errorShowType = 3;
  return body;
};

// 当头部数据更新时需要更新缓存数据，使用此方法序列化参数
export const serializeQueryArgsWithHeaderArgs: SerializeQueryArgs<any> = ({
  endpointName,
  endpointDefinition,
  queryArgs,
}) => {
  return defaultSerializeQueryArgs({
    endpointName,
    endpointDefinition,
    queryArgs: {
      ...(queryArgs || {}),
    },
  });
};
